@import '/src/helpers/constants';


.integration {
    &Content {
        display: flex;
        flex-direction: column;
      padding-top: 78px;
      &Text {
          font-size: 18px;
        margin-bottom: 16px;
        &Gray {
          color: $textSecondary;
          font-size: 14px;
          margin-bottom: 24px;
        }

        &Icon {
          display: flex;
          align-items: center;
          font-size: 18px;
        }
      }

      .copyButton {
        border: 0;
        margin-right: 20px;
        transform: scale(1.3);
      }

      &Button {
        margin-top: 24px;
        width: 280px;
        margin-bottom: 64px ;
      }
    }

  &InputWrapper {
    width: 840px;
    margin-top: 24px;
  }

  .integrationInput {
    height: 1070px;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &Dropdown {
    width: 840px;
    margin-top: 64px;
  }
}

.attributes {
  max-height: 0;
  overflow-y: hidden;
  transition: max-height .4s;

  &Open {
    max-height: 1027px;
  }

  &Inner {
    height: 1019px;
    margin-top: 8px;
    border-radius: 12px;
    overflow: hidden;
  }

  &Table {
    & th {
      height: 77px;
      background-color: $primaryBg;
      padding: 0;
    }
    & td {
      min-height: 74px;
      background-color: #fff;
      padding: 0;
    }

    &Cell {
      text-align: start;
      padding: 10px 12px;
      font-size: 14px;
      font-family: Manrope-Regular, sans-serif;
      height: 100%;
      white-space: normal;
    }
  }
}
