@import '/src/helpers/constants';

:root {
  --widget-main-color: #645375;
  --widget-background-color: #FFFFFF;
  --widget-secondary-color: #F2EAFB;
  --widget-third-color: #e8d9fa;
  --widget-text-color: #250347;
  --widget-button-color: #620479;
}

.wrapper {
  max-width: 480px;
  margin: 0 auto;
  //height: calc(100vh + 10px);
  height: 100vh;
  max-height: 900px;
  overflow: hidden;
}

.container {
  padding: 24px 20px;
  background-color: var(--widget-background-color);
  //max-height: 700px;
  height: calc(100% - 68px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  height: 120px;
}

.clickable {
  cursor: pointer;
  transition: background-color .2s;

  &:hover {
    background-color: var(--widget-secondary-color);
  }

  &:active {
    background-color: var(--widget-third-color);
  }
}

.clickableOpacity {
  cursor: pointer;
  transition: opacity .2s;

  &:hover {
    opacity: 0.7;
  }

  &:active {
    opacity: 0.4;
  }
}

.header {
  height: 68px;
  width: 100%;
  background-color: var(--widget-main-color);
  padding: 0 10px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .headerLeft {
    display: flex;
    align-items: center;
    gap: 12px;
    max-width: 82%;
  }

  .headerRight {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  .title {
    font-size: 18px;
    font-family: Manrope-Medium, sans-serif;
    font-weight: 500;
    color: var(--widget-background-color);
  }
}

.languageButton {
  position: relative;
  width: 60px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-family: Manrope-Regular, sans-serif;
  font-weight: 400;
  background-color: var(--widget-background-color);
  border-radius: 12px;
  z-index: 2;
  color: var(--widget-text-color);

  .languageDropdown {
    position: absolute;
    left: -2px;
    top: -2px;
    padding: 2px;
    background-color: var(--widget-background-color);
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 14px;
    cursor: default;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s;

    &Visible {
      opacity: 1;
      visibility: visible;
    }
  }
}

.slotsState {
  max-height: calc(100% - 130px);
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: space-between;
    align-items: center;
  }

  .mainPart {
    //height: 410px;
    overflow-y: auto;
  }

  .text {
    font-size: 16px;
    color: var(--widget-text-color);
    font-family: Manrope-Regular, sans-serif;
    font-weight: 400;
  }

  .mediumText {
    font-size: 18px;
    color: var(--widget-text-color);
    font-family: Manrope-Medium, sans-serif;
    font-weight: 500;
  }

  .phoneNumber {
    color: var(--widget-button-color);
    white-space: nowrap;
    text-decoration: underline;
  }

  .buttons {
    display: flex;
    gap: 8px;
  }

  .button {
    border: 0;

    &:hover {
      background-color: var(--widget-secondary-color);
    }

    &:active {
      background-color: var(--widget-third-color);
    }
  }
}

.customCalendarHeader {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  height: 20px;
  margin-bottom: 20px;

  .headerText {
    font-size: 16px;
    color: var(--widget-text-color);
    font-family: Manrope-Regular, sans-serif;
    font-weight: 400;
  }

  .headerArrows {
    display: flex;
    gap: 8px;
    align-items: center;
  }

  .headerArrow {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 12px;
    transition: background-color .2s;

    &:hover {
      background-color: var(--widget-secondary-color);
    }

    &:active {
      background-color: var(--widget-third-color);
    }

    &Left {
      transform: rotate(180deg);
    }
  }
}

.dateInputRow {
  padding-right: 7px;
}

.slotsRow {
  display: flex;
  align-items: center;
  align-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  height: 100%;
  overflow-y: auto;

  .slot {
    width: 76px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: 1px solid #E6E8EB;

    & .text {
      transition: color .2s;
    }

    &Selected {
      background-color: var(--widget-main-color);

      & .text {
        color: var(--widget-background-color);
      }
    }
  }
}

.myButton {
  width: 100%;
  background-color: var(--widget-main-color);

  &Disabled {
    background-color: var(--widget-secondary-color) !important;
  }

  &:hover:not(.myButtonDisabled) {
    background-color: var(--widget-text-color) !important;
  }
}

.myButtonSecondary {
  border-color: var(--widget-main-color) !important;

  &Text {
    color: var(--widget-main-color) !important;
  }
}

.modalButton {
  width: 160px;
}

.policyText {
  font-size: 13px;
  color: var(--widget-main-color);
  font-family: Manrope-Regular, sans-serif;
  font-weight: 400;
}

.arrowButton {
  border: 0;
  background-color: transparent;

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent !important;
  }
}

.inputsState {
  overflow-y: auto;
  margin-bottom: 20px;
  position: relative;
  .inputsTitle {
    font-size: 18px;
    color: var(--widget-text-color);
    font-family: Manrope-Medium, sans-serif;
    font-weight: 500;
  }

  .input {
      &:hover {
        border-color: var(--widget-button-color) !important;
      }

    &Large {
      height: 140px;
    }
  }
}

.closeModal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  padding: 32px;

  &Content {}

  &Title {
    font-size: 18px;
    color: var(--widget-main-color);
    font-family: Manrope-SemiBold, sans-serif;
    font-weight: 500;
  }

  &Buttons {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
  }

  &Button {
    width: calc(50% - 4px);
  }
}

.widgetDateInput {
  max-width: 480px;
  width: 100vw;
  box-shadow: none;
}

.captchaView {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.55);
}

.shareRow {
  display: flex;
  align-items: center;
  gap: 8px;

  .shareButton {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    transition: opacity .2s;

    &:hover {
      opacity: 0.96;
    }

    &:active {
      opacity: 0.84;
    }

    &.telegram {
      background-color: #2DA3E0;
    }

    &.whatsapp {
      background-color: #23D366;
    }
  }
}
